<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->

      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        :class="`d-none d-lg-flex flex-column align-items-center justify-content-between p-0 bg-primary`"
        style="height: 100vh"
      >
        <div
          class="bg-white w-100 text-center d-flex align-items-center justify-content-center"
          style="height: 20%"
        >
          <b-img
            v-if="!isOCB"
            :src="require('@/assets/images/logo/tingedu-logo-horizontal.png')"
            style="max-width: 50%; max-height: 60%"
          ></b-img>
          <div v-else class="d-flex flex-row">
            <b-img
              :src="require('@/assets/images/logo/logo-ocb.svg')"
              style="max-width: 80%; max-height: 80%"
            ></b-img>
            <div
              style="width: 2px; background-color: #e39717"
              class="rounded align-items-stretch mx-2"
            ></div>
            <div
              class="text-left d-flex flex-column justify-content-center align-items-center"
            >
              <!-- <div style="color: #002b70">Ngân hàng TMCP Phương Đông</div> -->
              <div
                style="color: #e39717; font-style: italic"
                class="font-medium-3"
              >
                Niềm tin và thịnh vượng
              </div>
              <!-- <div style="color: #002b70">Thương hiệu Quốc gia 2020 - 2022</div> -->
            </div>
          </div>
        </div>
        <div
          class="w-100 text-center d-flex flex-column align-items-center justify-content-center font-weight-bolder bg-primary"
          style="height: 80%; background-color: #af2031"
        >
          <div v-if="isOCB" style="font-size: 86px" class="text-white">
            OCB SCHOOL
          </div>
          <div
            v-if="isOCB"
            class="text-uppercase"
            style="color: #fee01c; font-size: 30px"
          >
            Giải pháp thu & quản lý học phí tự động
          </div>
          <!-- <div
            v-if="isOCB"
            class="bg-white text-primary mt-3"
            style="
              padding-top: 4px;
              padding-bottom: 4px;
              padding-left: 40px;
              padding-right: 40px;
              border-radius: 40px;
            "
          >
            Thông tin liên hệ
          </div>
          <div v-if="isOCB" class="text-white mt-1" style="font-size: 18px">
            Ban Dự án Open Banking
          </div>
          <div v-if="isOCB" class="mt-20">
            <span><feather-icon icon="MailIcon" class="text-white" /></span
            ><span class="text-white pl-50 font-weight-normal"
              >openbanking@ocb.com.vn</span
            >
          </div> -->

          <div
            v-if="!isOCB"
            class="text-uppercase text-white"
            style="font-size: 40px"
          >
            Phần mềm quản lý
          </div>
          <div
            v-if="!isOCB"
            class="text-uppercase pt-0"
            style="color: #f5c116; font-size: 40px"
          >
            Thu phí tự động
          </div>
          <div v-if="!isOCB" class="text-white pt-3" style="font-size: 16px">
            Thiết kế dành riêng cho lĩnh vực
          </div>
          <div
            v-if="!isOCB"
            class="text-uppercase font-weight-bolder m-1 p-1"
            style="
              background-color: #f5c116;
              color: #002b70;
              font-size: 30px;
              border-color: #002b70;
              border-radius: 15px;
              border-width: 2px;
            "
          >
            Giáo dục và đào tạo
          </div>
          <div
            v-if="!isOCB"
            class="text-uppercase pt-2"
            style="color: #f5c116; font-size: 16px"
          >
            Liên hệ ngay với chúng tôi
          </div>
          <div v-if="!isOCB" class="d-flex flex-row align-items-stretch mt-1">
            <div class="bg-white">
              <b-img
                :src="require('@/assets/images/pages/website_qr.svg')"
              ></b-img>
            </div>
            <div
              class="text-left pl-50 d-flex flex-column justify-content-between"
            >
              <div>
                <span
                  ><feather-icon
                    icon="PhoneIcon"
                    style="color: #f5c116" /></span
                ><span class="text-white pl-50">0247 1088 968</span>
              </div>
              <div>
                <span
                  ><feather-icon icon="MailIcon" style="color: #f5c116" /></span
                ><span class="text-white pl-50">edu@tingee.vn</span>
              </div>
              <div>
                <span
                  ><feather-icon
                    icon="GlobeIcon"
                    style="color: #f5c116" /></span
                ><span class="text-white pl-50">www.edu.tingee.vn</span>
              </div>
              <div>
                <span
                  ><feather-icon
                    icon="MapPinIcon"
                    style="color: #f5c116" /></span
                ><span class="text-white pl-50">Hà Nội & TP. Hồ Chí Minh</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="">
          <b-img :src="require('@/assets/images/illustration/leftimg.svg')">
          </b-img>
        </div>
        <div
          class="text-white flex-row align-items-center justify-content-center"
        >
          <h3 class="text-uppercase text-white font-weight-bold text-center">
            Phần mềm Quản lý thu phí tự động
          </h3>
          <h3
            class="text-uppercase text-white font-weight-bold text-center mt-1"
          >
            dành riêng cho lĩnh vực Giáo dục & Đào tạo
          </h3>
          <div class="d-flex align-items-center justify-content-center mt-4">
            <b-img
              style="width: 50%"
              :src="
                require('@/assets/images/logo/tingedu-logo-white-horizontal.png')
              "
            />
          </div>
        </div> -->
        <!-- <div class="">
          <b-img :src="require('@/assets/images/illustration/rightimg.svg')">
          </b-img>
        </div> -->

        <!-- <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-0"
        >
          <b-img
            fluid
            :src="brandConfig.loginBgImage"
            alt="Login"
            class=""
            style="max-height: 80vh"
          />
        </div> -->
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            {{ brandConfig.loginWelcomeText }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ brandConfig.loginDescriptionText }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- phone -->
              <b-form-group label="Số điện thoại">
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  vid="phone"
                  rules="required"
                >
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    autocomplete="off"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    name="phone"
                    placeholder="0868987355"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Mật khẩu</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="Mật khẩu"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="d-flex justify-content-between mb-2">
                <!-- checkbox -->
                <!-- <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="isManager"
                    name="checkbox-1"
                  >
                    Đăng nhập tài khoản quản lý
                  </b-form-checkbox>

                </b-form-group> -->

                <div />
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small>Quên mật khẩu?</small>
                </b-link>
              </div>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || loggingIn"
              >
                <b-spinner v-if="loggingIn" small />
                {{ loggingIn ? "Đang đăng nhập" : "Đăng nhập" }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Chưa có tài khoản? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Đăng ký ngay</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { $themeConfig, $brandConfig } from "@themeConfig";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
// eslint-disable-next-line import/no-cycle
import useJwt from "@/auth/jwt/useJwt";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
// eslint-disable-next-line import/no-cycle
import md5 from "md5";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Brand from "@/layouts/components/Brand.vue";
import { getHomeRouteForLoggedInUser, updateUserAbilities } from "@/auth/utils";
import Logo from "@/layouts/components/Logo.vue";
import { isObject } from "@vueuse/core";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    Brand,
    Logo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      phone: "",
      password: "",
      isManager: false,

      // validation rules
      required,
      loggingIn: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      this.loggingIn = true;
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              phone: this.phone,
              password: md5(this.password),
              role: null,
            })
            .then((response) => {
              this.loggingIn = false;
              const { user, accessToken, refreshToken } = response.data;
              useJwt.setToken(accessToken);
              useJwt.setRefreshToken(refreshToken);
              localStorage.setItem("userData", JSON.stringify(user));

              useJwt
                .getPermissions()
                .then((permissionResponse) => {
                  updateUserAbilities(permissionResponse.data);
                  this.$ability.update(permissionResponse.data);
                  this.$router
                    .replace(getHomeRouteForLoggedInUser())
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: `Xin chào ${user.name}`,
                          icon: "UserIcon",
                          variant: "success",
                          text: `Bạn đang đăng nhập với vai trò ${
                            user.role ? user.role.name : "admin"
                          }!`,
                        },
                      });
                    });
                  this.loggingIn = false;
                })
                .catch((error) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Thông báo",
                      text: error.response.data.message
                        ? error.response.data.message
                        : "Tài khoản chưa được phân quyền",
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                  this.loggingIn = false;
                  localStorage.removeItem("userData");
                  localStorage.removeItem("refreshToken");
                  localStorage.removeItem("accessToken");
                });
            })
            .catch((error) => {
              //
              if (
                error.response &&
                error.response.data &&
                error.response.data.errors
              ) {
                this.$refs.loginForm.setErrors(error.response.data.errors);
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Thông báo",
                    text: error.response.data.message
                      ? error.response.data.message
                      : "Lỗi hệ thống. Vui lòng chờ trong giây lát hoặc liên hệ hỗ trợ kỹ thuật để được trợ giúp kịp thời. Hotline liên hệ: 0868987355",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }
              this.loggingIn = false;
            });
        }
      });
    },
  },

  setup() {
    const { appName, appLogoImage, appLogoImageWhite } = $themeConfig.app;

    const brandConfig = $brandConfig;
    const isOCB = process.env.VUE_APP_PROVIDER === "ocb";

    return {
      appName,
      appLogoImage,
      appLogoImageWhite,
      brandConfig,
      isOCB,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
